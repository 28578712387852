import { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperType from "swiper/swiper";
import { sanitize } from "isomorphic-dompurify";
import { motion } from "framer-motion";
import {
  isAvailable,
  PortraitSliderType,
  PortraitSliderMediaType,
  ImageType,
  LinkType,
  VideoFileType,
  PageType,
} from "../../utils/baseTypes";
import { Section } from "../Global/Section";
import Arrow from "../../images/arrow_dark.svg";
import Image from "next/image";
import Media from "../Media/Media";
import { FrameWrapper } from "../Global/FrameWrapper";
import { unlinkWiki } from "../../utils/unlinkWiki";
import ReactPlayer from "react-player";
import {
  defaultEase,
  defaultTransition,
  scale_blur_variant,
} from "../../utils/transition";
import { getRelativePathFromLink } from "../../utils/url";
import { CustomLink } from "../Global/CustomLink";
import { InternalVideo } from "../Global/Video";
import { CTASlideType, PortraitCTASlide } from "./PortraitCTASlide";

interface PortraitSlider {
  data: PortraitSliderType[];
  sliderType?:
    | "textmedia-slider"
    | "image-slider"
    | "video-slider"
    | "portfolio-slider"
    //todo remove this when correct "portfolio-slider" value added in backend
    | "portrait-slider";
  sectionClassName?: string;
  background?: string;
  enableContainer?: boolean;
  portraitSliderPortfolio: PageType[];
  cta_slider?: CTASlideType;
}

const button_inview_variant = {
  x: 0,
  transition: {
    ease: defaultEase,
    duration: 2,
  },
};

const slider_variant = {
  initial: {
    scale: 0.9,
    x: 500,
    opacity: 0,
  },
  animate: (index: number) => ({
    scale: 1,
    x: 0,
    opacity: 1,
    transition: {
      ...defaultTransition,
      delay: index * 0.12,
      staggerChildren: 0.3,
    },
  }),
};

export const PortraitSlider = ({
  data,
  sectionClassName,
  sliderType,
  background,
  enableContainer,
  portraitSliderPortfolio,
  cta_slider,
}: PortraitSlider) => {
  const swiperRef = useRef<SwiperType>();
  let imagesSizes = "";

  if (sliderType === "textmedia-slider" || sliderType === "image-slider") {
    imagesSizes =
      "(max-width: 765px) 70vw, (max-width: 1023px) 37vw, (max-width: 1407px) 22vw, 17vw";
  } else if (sliderType === "video-slider") {
    imagesSizes =
      "(max-width: 768px) 95vw, (max-width: 1023px) 50vw, (max-width: 1440px) 33vw, 25vw";
  } else if (sliderType === "portfolio-slider") {
    imagesSizes =
      "(max-width: 768px) 95vw, (max-width: 1023px) 50vw, (max-width: 1440px) 33vw, 25vw";
  } else if (sliderType === "portrait-slider") {
    imagesSizes =
      "(max-width: 768px) 95vw, (max-width: 1023px) 50vw, 33vw, 25vw";
  }

  useEffect(() => {
    // setSliderData(data);

    if (swiperRef.current) {
      swiperRef.current.update();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let sliderJSX;

  switch (sliderType) {
    case "image-slider":
      sliderJSX = data.map((slide, i) => {
        return (
          <SwiperSlide key={i}>
            <ImageSlide slide={slide} index={i} />
          </SwiperSlide>
        );
      });
      break;
    case "textmedia-slider":
      sliderJSX = data.map((slide, i) => {
        const media = isAvailable<PortraitSliderMediaType>(
          slide.portraitslider_media
        )
          ? slide.portraitslider_media
          : null;

        const link = isAvailable<LinkType>(slide.portraitslider_link)
          ? slide.portraitslider_link
          : undefined;

        return (
          <SwiperSlide key={i}>
            <motion.div custom={i} variants={slider_variant}>
              <FrameWrapper wrapLink={link !== undefined} link={link}>
                <div className="portrait-slide textmedia-slider">
                  <div>
                    {media && (
                      <div className="media-wrapper">
                        <Media
                          data={{
                            media: {
                              image: media?.portraitslider_media_image!,
                              image_tablet: media?.portraitslider_media_image!,
                              image_mobile: media?.portraitslider_media_image!,
                              type: media?.portraitslider_media_type!,
                              svg: media?.portraitslider_media_svg,
                              svg_animation:
                                media?.portraitslider_media_svg_animation,
                              video: false,
                            },
                          }}
                          noSection
                          //! below height and width only work for svg
                          height={90}
                          width={90}
                          sizes={imagesSizes}
                        />
                      </div>
                    )}
                    <div className="portrait-content">
                      <span className="h3 mb-0">
                        <strong
                          dangerouslySetInnerHTML={{
                            __html: sanitize(
                              unlinkWiki(slide.portraitslider_title)
                            ),
                          }}
                        ></strong>
                      </span>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(slide.portraitslider_content, {
                            ADD_ATTR: ["target"],
                          }),
                        }}
                      />
                    </div>
                  </div>

                  {link && (
                    <div className="arrow">
                      <Arrow width={22.06} height={14.58} />
                    </div>
                  )}
                </div>
              </FrameWrapper>
            </motion.div>
          </SwiperSlide>
        );
      });
      break;
    case "video-slider":
      sliderJSX = (
        <>
          {data.map((slide, i) => {
            const video = isAvailable<VideoFileType>(slide.portraitslider_video)
              ? slide.portraitslider_video
              : undefined;
            const poster = isAvailable<ImageType>(slide.portraitslider_poster)
              ? slide.portraitslider_poster
              : undefined;

            return (
              <SwiperSlide key={i}>
                <motion.div
                  custom={i}
                  variants={slider_variant}
                  className="portrait-slide video-slider"
                >
                  {video && (
                    <InternalVideo
                      video={video}
                      poster={poster}
                      autoplay={false}
                      enableAudioToggle={false}
                    />
                  )}
                  {/* <ReactPlayer
                width={"100%"}
                height={"100%"}
                url={video?.url!}
                light={poster?.url!}
                playing
                controls
              /> */}
                </motion.div>
              </SwiperSlide>
            );
          })}
          {cta_slider && (
            <SwiperSlide>
              <PortraitCTASlide CTA={cta_slider} />
            </SwiperSlide>
          )}
        </>
      );

      break;
    case "portfolio-slider":
      sliderJSX = (
        <>
          {portraitSliderPortfolio.map((slide, i) => {
            return (
              <SwiperSlide key={i}>
                <PortfolioSlide slide={slide} index={i} />
              </SwiperSlide>
            );
          })}
          {cta_slider && (
            <SwiperSlide>
              <PortraitCTASlide CTA={cta_slider} />
            </SwiperSlide>
          )}
        </>
      );
      break;
    //todo remove this when correct "portfolio-slider" value added in backend
    case "portrait-slider":
      sliderJSX = portraitSliderPortfolio.map((slide, i) => {
        return (
          <SwiperSlide key={i}>
            <PortfolioSlide slide={slide} index={i} />
          </SwiperSlide>
        );
      });
      break;
    default:
      sliderJSX = <></>;
      break;
  }

  return (
    <div className={`portrait-slider ${background}`}>
      <Section
        disableColumns
        disableContainer={!enableContainer}
        className={sectionClassName}
        isClipped={false}
      >
        <motion.div className="portrait-slider-swiper-wrapper">
          <Swiper
            speed={700}
            slidesPerView={4.5}
            breakpoints={{
              100: {
                slidesPerView: 1.5,
              },
              490: {
                slidesPerView: 2.5,
              },
              766: {
                slidesPerView: 3.5,
              },
              1024: {
                slidesPerView: 3.5,
              },
              1440: {
                slidesPerView: 4.5,
              },
            }}
            draggable={true}
            spaceBetween={20}
            observer={true}
            loop={false}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {sliderJSX}
          </Swiper>
        </motion.div>
        <motion.div
          initial={"initial"}
          whileInView={"animate"}
          viewport={{ amount: 1, once: true }}
          className="portrait-slider-navigations"
        >
          <div>
            <motion.button
              variants={{
                initial: {
                  rotate: 180,
                  x: "calc(100% - 22.06px)",
                },
                animate: {
                  ...button_inview_variant,
                },
              }}
              className="prev"
              aria-label="prev"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <Arrow width={22.06} height={14.58} />
            </motion.button>
          </div>
          <div>
            <motion.button
              variants={{
                initial: {
                  x: "calc(-1 * calc(100% - 22.06px))",
                },
                animate: {
                  ...button_inview_variant,
                },
              }}
              className="next"
              aria-label="next"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <Arrow width={22.06} height={14.58} />
            </motion.button>
          </div>
        </motion.div>
      </Section>
    </div>
  );
};

interface IPortfolioSlide {
  slide: PageType;
  index: number;
}

const PortfolioSlide = ({ slide, index }: IPortfolioSlide) => {
  const header = slide.acf?.header_project!;

  const image = header.portrait_slider_image
    ? header.portrait_slider_image
    : null;

  const logo = isAvailable<ImageType>(header.logo) ? header.logo : null;

  return (
    <CustomLink
      href={getRelativePathFromLink(slide.link)}
      target={"_self"}
      className="portrait-slide"
      disablePrefetch
    >
      <motion.div
        custom={index}
        variants={slider_variant}
        className="portfolio-slide"
      >
        <div className="portrait-content-wrapper">
          <div className="portrait-content">
            <div>
              <span
                className="overline"
                dangerouslySetInnerHTML={{
                  __html: sanitize(unlinkWiki(header.portrait_slider_overline)),
                }}
              ></span>
              <span className="h3 mb-0">
                <strong
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      unlinkWiki(header.portrait_slider_headline)
                    ),
                  }}
                ></strong>
              </span>
            </div>
          </div>
        </div>
        {image && (
          <motion.span
            variants={{
              initial: scale_blur_variant.initial,
              animate: {
                ...scale_blur_variant.animate,
                transition: {
                  ease: defaultEase,
                  duration: 1.5,
                  delay: index * 0.18,
                },
              },
            }}
          >
            <Image
              src={image.url!}
              alt={image.alt}
              data-title={image.title}
              height={image.height}
              width={image.width}
              // priority={index === 0} //disable priority because of lighthouse
              sizes="(max-width: 768px) 70vw, 25vw"
            />
          </motion.span>
        )}
        <div className="corner-div" />
        <div className="corner-div" />
        <div className="hover-content">
          <div className="logo-wrapper">
            {logo && (
              <Image
                alt={logo.alt}
                src={logo.url!}
                fill
                style={{ objectFit: "contain", objectPosition: "center" }}
                sizes="(max-width: 768px) 95vw, (max-width: 1023px) 50vw, (max-width: 1440px) 33vw, 25vw"
              />
            )}
          </div>
        </div>
      </motion.div>
    </CustomLink>
  );
};

interface ISlide {
  slide: PortraitSliderType;
  index: number;
}

const ImageSlide = ({ slide, index }: ISlide) => {
  const image = isAvailable<ImageType>(slide.portraitslider_image_slider)
    ? slide.portraitslider_image_slider
    : null;

  const link = isAvailable<LinkType>(slide.portraitslider_link)
    ? slide.portraitslider_link
    : { title: "", target: "", url: "#" };

  return (
    <motion.div custom={index} variants={slider_variant}>
      <CustomLink
        href={link.url}
        target={link.target}
        className="portrait-slide image-slider"
        disablePrefetch
      >
        <div className="portrait-content-wrapper">
          <div className="portrait-content">
            <div>
              <span
                className="overline"
                dangerouslySetInnerHTML={{
                  __html: sanitize(unlinkWiki(slide.portraitslider_overline)),
                }}
              ></span>
              <span className="h3 mb-0">
                <strong
                  dangerouslySetInnerHTML={{
                    __html: sanitize(unlinkWiki(slide.portraitslider_title)),
                  }}
                ></strong>
              </span>
            </div>
            <div>
              <button className="arrow">
                <Arrow width={22.06} height={14.58} />
              </button>
            </div>
          </div>
        </div>
        {image && (
          <Image
            src={image.url!}
            alt={image.alt}
            data-title={image.title}
            height={image.height}
            width={image.width}
            sizes="(max-width: 768px) 70vw, 25vw"
          />
        )}
      </CustomLink>
    </motion.div>
  );
};
