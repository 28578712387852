import { sanitize } from "isomorphic-dompurify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useEffect, useRef, useState } from "react";
import { getDataArray } from "../../utils/getData";
import { ToolTipType } from "../../utils/baseTypes";

const Tooltip = ({}) => {
  const [tooltipData, setTooltipData] = useState<ToolTipType>({
    title: "",
    excerpt: "",
    link: "",
  });
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const isHovering = useRef<boolean>(false);

  useEffect(() => {
    const linkList: HTMLAnchorElement[] = [];

    const handleLinkHover = async (event: MouseEvent) => {
      event.preventDefault();
      const { target } = event;
      isHovering.current = true;
      setShowTooltip(true);
      console.log("handleLinkHover");

      if (
        target instanceof HTMLAnchorElement &&
        target.classList.contains("encyclopedia")
      ) {
        const url = new URL(target.href);
        const slug = url?.pathname
          .split("/")
          .filter((segment) => segment !== "")
          .pop();
        // Fetch data for the encyclopedia custom post type
        try {
          const tooltipContent = await getDataArray(
            "/encyclopedia?slug=" + slug + `&_fields=acf.wiki_header,link,title`
          ).then(({ pageData }) => {
            return pageData.length > 0 ? pageData[0] : null;
          });

          if (tooltipContent && isHovering.current) {
            setTooltipData({
              title:
                tooltipContent.acf?.wiki_header?.headline ||
                tooltipContent.title.rendered,
              excerpt: sanitizeExcerpt(
                tooltipContent.acf?.wiki_header?.content ?? ""
              ),
              link: tooltipContent.link,
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    const handleLinkLeave = (event: MouseEvent) => {
      event.preventDefault();
      isHovering.current = false;
      setShowTooltip(false);
    };
    const detachListeners = () => {
      linkList.forEach((link: HTMLAnchorElement) => {
        link.removeEventListener("mouseenter", handleLinkHover);
        link.removeEventListener("mouseleave", handleLinkLeave);
      });
    };

    // Create an observer instance
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            const links =
              node.querySelectorAll<HTMLAnchorElement>("a.encyclopedia");
            links.forEach((link) => {
              if (!linkList.includes(link)) {
                link.addEventListener("mouseover", handleLinkHover);
                link.addEventListener("mouseout", handleLinkLeave);
                linkList.push(link);
              }
            });
          }
        });
      });
    });

    // Start observing the document with the configured parameters
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Initial setup for existing links
    document
      .querySelectorAll<HTMLAnchorElement>("a.encyclopedia")
      .forEach((link) => {
        link.addEventListener("mouseenter", handleLinkHover);
        link.addEventListener("mouseleave", handleLinkLeave);
        linkList.push(link);
      });

    //Glossar add EventsListeners

    return () => {
      observer.disconnect();
      detachListeners();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sanitizeExcerpt = (excerpt: string): string => {
    const charLimit: number = 225; // Adjust the desired character limit here
    let sanitizedExcerpt = stripHtmlTags(sanitize(excerpt));
    if (sanitizedExcerpt.length > charLimit) {
      sanitizedExcerpt = sanitizedExcerpt.substring(0, charLimit) + " ...";
    }
    return sanitizedExcerpt;
  };

  const stripHtmlTags = (html: string): string => {
    const tempElement = document?.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  return (
    <ReactTooltip
      clickable
      id="glossar-tooltip"
      anchorSelect={".encyclopedia"}
      place="top"
      className="glossar-tooltip"
      isOpen={showTooltip}
      afterHide={() => {
        setTooltipData({
          title: "",
          excerpt: "",
          link: "",
        });
      }}
    >
      {(tooltipData.title === "" || tooltipData.excerpt === "") && (
        <div className="loader" />
      )}

      {tooltipData.title && (
        <span
          className="tooltip-title"
          dangerouslySetInnerHTML={{ __html: sanitize(tooltipData.title) }}
        />
      )}
      {tooltipData.excerpt && (
        <span
          className="tooltip-content"
          dangerouslySetInnerHTML={{ __html: tooltipData.excerpt }}
        />
      )}
    </ReactTooltip>
  );
};

export default Tooltip;
