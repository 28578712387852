import { sanitize } from "isomorphic-dompurify";
import Image from "next/image";
import dayjs from "dayjs";
import { motion } from "framer-motion";

import { PageType, OptionsType } from "../../utils/baseTypes";

import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import Divider from "../Divider/Divider";

import { Section } from "../Global/Section";
import Link from "next/link";
import { getRelativePathFromLink } from "../../utils/url";
import { ScrollLinkNoArrw } from "./ScrollLink";
import { unlinkWiki } from "../../utils/unlinkWiki";

import { useDividerAnimation } from "../../utils/useDividerAnimation";

import { InsightBlogCast } from "../InsightBlogCast/InsightBlogCast";

import Instagram from "../../images/insight_header/share_instagram.svg";
import Facebook from "../../images/insight_header/share_facebook.svg";
interface InsightHeaderProp {
  post: PageType;
  options: OptionsType;
}

const transition_ = {
  ease: [0.455, 0.03, 0.515, 0.955],
  duration: 0.85,
};

const text_variants = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: transition_,
  },
};

const container = {
  visible: {
    transition: {
      delay: 0.5,
      staggerChildren: 0.1,
    },
  },
};

const stagger_variant = {
  visible: {
    transition: {
      delay: 0.5,
      staggerChildren: 0.3,
    },
  },
};

const linkGenerator = (modules: any[]) => {
  const links = [];
  const blacklist = ["cta"];
  for (let index = 0; index < modules.length; index++) {
    const module_raw = modules[index];
    const acf_fc_layout = module_raw.acf_fc_layout;
    if (!blacklist.includes(acf_fc_layout)) {
      if (module_raw[acf_fc_layout].headline) {
        links.push({
          name: module_raw[acf_fc_layout].headline,
          id: module_raw.layout.id,
        });
      }
    }
  }
  return links;
};

const InsightHeader = ({ post, options }: InsightHeaderProp) => {
  const links = linkGenerator(post.acf?.module as any[]);
  const overview = post.acf?.header_insights?.overview === "on";
  const animation = post.acf?.header_insights?.animation;
  const plattforms = options.acf.plattform;

  const getSocialLink = (plattform: string) => {
    const plattformData = plattforms.filter((el) => el.name === plattform)[0];
    return plattformData?.link;
  };

  useDividerAnimation(animation ?? "");

  const background = post.acf?.header_insights?.background!;
  const navigationBG =
    background === "has-white-bg" ? "has-grey-bg" : "has-white-bg";

  const authorInfoJSX = (
    <motion.div className="author-info" variants={text_variants}>
      <div className="author-image-wrapper">
        {post.author_info?.link ? (
          <Link
            prefetch={false}
            href={getRelativePathFromLink(post.author_info.link)}
            aria-label="author-info"
          >
            <figure className="image is-107x107">
              {post.author_info.image && (
                <Image
                  className="is-rounded"
                  src={post.author_info?.image.url as string}
                  width={post.author_info?.image.width}
                  height={post.author_info?.image.height}
                  alt={post.author_info?.image.alt as string}
                  data-title={post.author_info?.image.title as string}
                  sizes="(max-width: 768px) 50vw, (max-width: 1023px) 25vw, (max-width: 1440px) 15vw, 10vw"
                />
              )}
            </figure>
          </Link>
        ) : post.author_info?.image ? (
          <figure className="image is-107x107">
            <Image
              className="is-rounded"
              src={post.author_info?.image.url as string}
              width={post.author_info?.image.width}
              height={post.author_info?.image.height}
              alt={post.author_info?.image.alt as string}
              data-title={post.author_info?.image.title as string}
              sizes="(max-width: 768px) 50vw, (max-width: 1023px) 25vw, (max-width: 1440px) 15vw, 10vw"
            />
          </figure>
        ) : null}
      </div>

      <div className="post-info">
        <div>
          <span style={{ textTransform: "capitalize" }}>
            {post.author_info?.gender ?? "Autorin"}:&nbsp;
          </span>
          {post.author_info?.title && (
            <span>
              <strong
                dangerouslySetInnerHTML={{
                  __html: sanitize(post.author_info.title),
                }}
              />
            </span>
          )}
        </div>

        <div className="post-dates">
          <span>{dayjs(post.date_gmt).format("DD.MM.YYYY")}</span>
          {post.acf?.header_insights?.show_updated_datum && (
            <span className="updated-date">
              Updated:&nbsp;
              {post.acf?.header_insights?.updated_datum}
            </span>
          )}
        </div>
        <div className="post-socials">
          <span>Teilen auf :</span>

          <motion.span
            whileHover={{
              scale: 1.2,
              transition: { type: "spring", stiffness: 400, damping: 17 },
            }}
          >
            <a
              href={getSocialLink("Facebook")}
              target="_blank"
              rel="noreferrer"
              aria-label="facebook"
            >
              <Facebook />
            </a>
          </motion.span>

          {/* <motion.span
            whileHover={{
              scale: 1.2,
              transition: { type: "spring", stiffness: 400, damping: 17 },
            }}
          >
            <a
              href={getSocialLink("Twitter")}
              target="_blank"
              rel="noreferrer"
              aria-label="twitter"
            >
              <Twitter />
            </a>
          </motion.span> */}
          <motion.span
            whileHover={{
              scale: 1.2,
              transition: { type: "spring", stiffness: 400, damping: 17 },
            }}
          >
            <a
              href={getSocialLink("Instagram")}
              target="_blank"
              rel="noreferrer"
              aria-label="instagram"
            >
              <Instagram />
            </a>
          </motion.span>
        </div>
      </div>
      {/* {blogcast && blogcast_iframe && <ReactPlayer url={blogcast_iframe} />} */}
    </motion.div>
  );

  return (
    <>
      <section
        className={`insight-header divider ${
          post.acf?.header_insights?.background
            ? post.acf?.header_insights.background
            : "has-white-bg"
        }`}
      >
        <Breadcrumbs post={post} />
        <Section disableColumns className={`is-gapless-top`}>
          <div className="header-info">
            {post.acf?.header_insights?.divider.divider_on && (
              <Divider
                data={post.acf?.header_insights?.divider}
                background={post.acf?.header_insights.background}
              />
            )}

            <motion.div
              className="content-wrapper"
              variants={container}
              initial="hidden"
              animate="visible"
            >
              <div className="columns is-multiline">
                <div className="column is-12 is-5-desktop title-wrapper">
                  <motion.h1
                    variants={text_variants}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        unlinkWiki(post.acf?.header_insights?.title as string),
                        {
                          ADD_ATTR: ["target"],
                        }
                      ),
                    }}
                  ></motion.h1>
                  {post.acf?.header_insights?.pos_info === "below-headline" &&
                    authorInfoJSX}
                </div>
                <div className="column is-12 is-offset-1-desktop is-6-desktop post-wrapper">
                  {post.acf?.header_insights?.pos_info === "over-text-right" &&
                    authorInfoJSX}
                  <motion.div
                    variants={text_variants}
                    className="post-description"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        post.acf?.header_insights
                          ? post.acf?.header_insights?.text
                          : "",
                        {
                          ADD_ATTR: ["target"],
                        }
                      ),
                    }}
                  ></motion.div>
                </div>
              </div>
            </motion.div>
          </div>
        </Section>
        {links.length > 0 && overview && (
          <Section
            disableColumns
            className={`insight-navigation ${navigationBG}`}
          >
            <motion.div
              variants={stagger_variant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <motion.h2 variants={text_variants}>
                {/* //todo add label */}
                <strong>Themenübersicht</strong>
              </motion.h2>
              <motion.div
                className="columns is-centered"
                variants={text_variants}
              >
                <div className="column is-12 navigation-wrapper two-columns">
                  <ul>
                    {links.map((link, i) => (
                      <li key={i}>
                        <ScrollLinkNoArrw
                          link={link}
                          className="navigation-link"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            </motion.div>
          </Section>
        )}
      </section>
      <InsightBlogCast post={post} options={options} />
    </>
  );
};

export default InsightHeader;
