import {
  PageType,
  OptionsType,
  HeaderSeiteType,
  EncyclopediaCategoryType,
} from "../../../utils/baseTypes";

import { useDividerAnimation } from "../../../utils/useDividerAnimation";
import dynamic from "next/dynamic";

import { TextHeader } from "./TextHeader";
import { checkSinglePage } from "../../../utils/checkSinglePage";
import { CTABoxHeader } from "./CTABoxHeader";
import { TextMediaHeader } from "./TextMediaHeader";
import HeroPage from "./HeroPage";
import { PortraitSliderHeader } from "./PortraitSliderHeader";
import { DividerHeader } from "./DividerHeader";
import { WikiPageHeader } from "./WikiPageHeader";
import { ScrollDown } from "../../ScrollDown/ScrollDown";

const HeaderAnimationPalyer = dynamic(
  () => import("../../HeaderDotPlayer/HeaderDotPlayer"),
  {
    loading: () => <></>,
    ssr: false,
  }
);

interface SeiteHeaderProp {
  post: PageType;
  options: OptionsType;
  wikiCategories: EncyclopediaCategoryType[];
  portraitSliderPortfolio: PageType[];
}

export const SeiteHeader = ({
  post,
  options,
  wikiCategories,
  portraitSliderPortfolio,
}: SeiteHeaderProp) => {
  const header = post.acf?.header_seite as HeaderSeiteType;

  const { header_type, animation } = header;

  const isSinglePage = checkSinglePage({ page: post });
  useDividerAnimation(animation);

  let mainJSX;
  switch (header_type) {
    case "text":
      mainJSX = (
        <TextHeader post={post} isSinglePage={isSinglePage} options={options} />
      );
      break;
    case "ctabox":
      mainJSX = <CTABoxHeader post={post} isSinglePage={isSinglePage} />;
      break;
    case "hero":
      mainJSX = (
        <HeroPage isSinglePage={isSinglePage} post={post} options={options} />
      );
      break;
    case "portraitslider":
      mainJSX = (
        <PortraitSliderHeader
          isSinglePage={isSinglePage}
          post={post}
          portraitSliderPortfolio={portraitSliderPortfolio}
          options={options}
        />
      );
      break;
    case "textmedia":
      mainJSX = (
        <TextMediaHeader
          post={post}
          isSinglePage={isSinglePage}
          options={options}
        />
      );
      break;
    case "animationslider":
      mainJSX = (
        <>
          <HeaderAnimationPalyer post={post} isSinglePage={isSinglePage} />
          <ScrollDown background="has-dark-bg" className="page-scroll-down" />
          <TextHeader
            post={post}
            isSinglePage={isSinglePage}
            options={options}
            disablehHeight={true}
            isAnimationSlider={true}
          />
        </>
      );
      break;
    case "divider":
      mainJSX = <DividerHeader post={post} isSinglePage={isSinglePage} />;
      break;
    case "wiki":
      mainJSX = (
        <WikiPageHeader
          post={post}
          isSinglePage={isSinglePage}
          wikiCategories={wikiCategories}
          options={options}
        />
      );
      break;
    default:
      mainJSX = (
        <TextHeader post={post} isSinglePage={isSinglePage} options={options} />
      );
      break;
  }

  return <>{mainJSX}</>;
};
